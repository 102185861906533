/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.button {
  background-color: #93acc6;
  font-size: 12px;
  line-height: 1.33333333;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #b4c6d8;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 90%;
}
@media only screen and (max-width: 767px) {
  .downlink {
    width: 50px;
    height: 29px;
    background-size: 50px 29px;
  }
  .vcard {
    width: 100%;
    margin-bottom: 0;
  }
  #view div.seam div.lead {
    bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 36px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part,
.area > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area .tiny {
  width: 46%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 4%;
  margin-left: 4%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92%;
}
.area > .slim .tiny {
  width: 92%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  width: 104%;
  margin-left: -2%;
}
.cb-layout1 div.main div.seam,
.cb-layout2 div.main div.seam {
  margin-left: 2%;
  margin-right: 2%;
}
.cb-layout1 div.main div.seam.wide,
.cb-layout2 div.main div.seam.wide {
  width: 96%;
}
.cb-layout1 div.main div.seam.slim,
.cb-layout2 div.main div.seam.slim {
  width: 46%;
}
@media (max-width: 767px) {
  .cb-layout1 div.main div.flat.wide .part.tiny {
    width: 16%;
  }
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #93acc6;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #93acc6;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #7292b4;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 20px;
  font-size: 2rem;
  display: inline-block;
  padding: 0.12em 1em;
  min-height: 1.2em;
  border: 1px solid;
  border-color: #829fbd #6186ac #6186ac #829fbd;
  border-radius: 2px;
  background-color: #93acc6;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(84, 121, 159, 0.75);
  line-height: 1.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #829fbd #6186ac #6186ac #829fbd;
  background-color: #9db4cb;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #829fbd #6186ac #6186ac #829fbd;
  background-color: #7292b4;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/*# sourceMappingURL=./screen-medium.css.map */